<template>
  <div>
    <!--begin::Forgot Password-->
    <div v-if="show" class="login-form login-forgot">
      <div v-if="status == 'form'">
        <!--begin::Title-->
        <div class="pb-5 pt-lg-0 pt-5">
            <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">Change Password</h3>
            <p class="text-muted font-weight-bold font-size-h6">
              Your new password must be different from previous used password. Check your email to get the uniq code.
            </p>
        </div>
        <!--end::Title-->

        <b-alert :show="(errors ? true : false)" fade>{{ errors }}</b-alert>

        <!--begin::Form-->
        <ValidationObserver ref="form">
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <validationProvider name="Code" rules="required|alpha_num" v-slot="{ errors, valid }">
            <b-form-group
              id="form-input-group-3"
              label=""
              :invalid-feedback="errors[0]"
              :state="valid"
              label-for="form-input-3"
            >
              <b-form-input
                class="form-control form-control-solid text-uppercase h-auto py-5 px-6"
                type="text"
                autocomplete="off"
                id="form-input-3"
                v-model="form.uuid"
                placeholder="CODE UNIQ"
                :state="valid"
                aria-describedby="input-3-live-feedback"
              ></b-form-input>
            </b-form-group>
          </validationProvider>

          <validationProvider name="Password" rules="required|password|min:8|max:150" v-slot="{ errors, valid }">
            <b-form-group
              id="form-input-group-1"
              label=""
              :invalid-feedback="errors[0]"
              :state="valid"
              description="Do not include common words or names."
              label-for="form-input-1"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-5 px-6"
                type="password"
                id="form-input-1"
                v-model="form.password"
                placeholder="New Password"
                :state="valid"
                aria-describedby="input-2-live-feedback"
              ></b-form-input>
            </b-form-group>
          </validationProvider>

          <validationProvider name="Password Confirmation" rules="required|min:8|max:150|matchPassword:@Password" v-slot="{ errors, valid }">
            <b-form-group
              id="form-input-group-2"
              label=""
              :invalid-feedback="errors[0]"
              :state="valid"
              label-for="form-input-2"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-5 px-6"
                type="password"
                id="form-input-2"
                v-model="form.password_confirmation"
                placeholder="Password Confirmation"
                :state="valid"
                aria-describedby="input-2-live-feedback"
              ></b-form-input>
            </b-form-group>
          </validationProvider>

          <!--begin::Action-->
          <div
            class="form-group d-flex flex-wrap pb-lg-0 mb-0"
          >
            <button
              ref="kt_change_password_submit"
              class="btn btn-primary font-weight-bolder font-size-h6 my-3 mr-4"
            >
              Submit
            </button>
            
          </div>
          <!--end::Action-->
        </b-form>
        </ValidationObserver>
        <!--end::Form-->
      </div>

      <div v-else>
        <div v-if="status == 'loading'">
          <ListLoader></ListLoader>
        </div>

        <div v-else>
          <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
            <span v-if="listStatus(status).logo" class="svg-icon svg-icon-4x" :class="`svg-icon-${listStatus(status).action}`">
              <!--begin::Svg Icon-->
              <inline-svg :src="`media/svg/icons/${listStatus(status).logo}`" :keepDuringLoading="false" />
              <!--end::Svg Icon-->
            </span>
            {{ listStatus(status).judul }}
          </h3>
          <p class="text-muted font-weight-bold font-size-h4 pb-5">
            {{ listStatus(status).desc }}
          </p>          
          <b-button ref="kt_change_password_send" v-if="listStatus(status).button.includes('send')" @click="onResend" class="m-1 font-weight-bolder font-size-h6" variant="primary">{{ listStatus(status).buttonName }}</b-button>
          <b-button v-if="listStatus(status).button.includes('logout')" @click="onLogout" class="m-1 font-weight-bolder font-size-h6" variant="light">Logout {{ timeLogout }}</b-button>
          <b-button v-if="listStatus(status).button.includes('dashboard')" @click="$router.push({ name: 'dashboard' }).catch(()=>{})" class="m-1 font-weight-bolder font-size-h6" variant="primary">Dashboard</b-button>
          <b-button v-if="listStatus(status).button.includes('profil')" @click="$router.push({ name: 'profile' }).catch(()=>{})" class="m-1 font-weight-bolder font-size-h6" variant="light">Profile</b-button>
        </div>
      </div>

    </div>
    <!--end::Forgot Password-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { ListLoader } from 'vue-content-loader'
import { LOGOUT, DELACCOUNT } from "@/core/services/store/auth.module";
import ProfileService from "@/core/services/api/profile.service";
import ErrorService from "@/core/services/error.service";
import { extend } from 'vee-validate';

extend('matchPassword', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend('password', {
  validate(value) {
    const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{1,}$/
    return value.match(validPassword);
  },
  message: 'Password must use a combination of uppercase (A-Z), lowercase (a-z) and numbers (0-9).'
});

export default {
  name: "auth-change-password",
  components: {
    ListLoader
  },
  data() {
    return {
      // Remove this dummy login info
      form: {
        uuid: this.$route.query.uuid || null,
        password: null,
        password_confirmation: null,
      },
      show: true,
      status: 'loading',
      timeLogout: null,
      timeLogoutProcess: null,
    };
  },
  methods: {
    listStatus(status) {
      if (status == 'notify') return {
        action: null,
        logo: null,
        judul: 'Change Password',
        desc: `This action requires for change password. Please check your inbox and follow intructions. Email Sent ${this.activeUserInfo.email}`,
        buttonName: 'Send',
        button: ['send', 'logout']
      }
      else if (status == 'send') return {
        action: 'primary',
        logo: 'Communication/Readed-mail.svg',
        judul: 'Check your email',
        desc: `We have sent a link to the change password page and uniq code to your email. Email Sent ${this.activeUserInfo.email}`,
        buttonName: 'Resend',
        button: ['send', 'logout']
      }
      else if (status == 'not-change') return {
        action: 'danger',
        logo: 'Communication/Mail-error.svg',
        judul: 'Invalid!!',
        desc: `Hallo ${this.activeUserInfo.name}. Please send a request to change password first.`,
        buttonName: null,
        button: ['dashboard', 'profil']
      }
      else if (status == 'change') return {
        action: 'primary',
        logo: 'Navigation/Double-check.svg',
        judul: 'Success!!',
        desc: `Hallo ${this.activeUserInfo.name}. Your password success updated. Please relogin aplication.`,
        buttonName: null,
        button: ['logout']
      }
      else return {
        action: null,
        logo: null,
        judul: null,
        desc: null,
        button: []
      }
    },
    getPasswordStatus() {
      ProfileService.statusPassword({token: this.$route.query.token})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.status = res.data.data
        })
        .catch((err) => ErrorService.status(err))
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onResend() {
      const getTime = window.localStorage.getItem("time_resend_cpswd")
      // set spinner to submit button
      const submitButton = this.$refs["kt_change_password_send"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if ((['send', 'notify']).includes(this.status) && getTime < (new Date().getTime())) { 
        // dummy delay
        // setTimeout(() => {
          ProfileService.resendPasswordVerify({param: this.$route.query.rget})
          .then((res) => {
            ErrorService.message(res.data)
            window.localStorage.setItem("time_resend_cpswd", (new Date().getTime()) + 600000);
            this.show = false
            this.status = 'send'
            this.$nextTick(() => {
              this.show = true
            })
            this.removeSpinner(submitButton)
          })
          .catch((err) => {
            this.removeSpinner(submitButton)
            ErrorService.status(err)})
          
        // }, 2000);
        return
      }
      
      // dummy delay
      // setTimeout(() => {
        ErrorService.message({
          action: "Peringatan!!",
          message: "Tidak dapat mengirim email, silahkan ulangi beberapa saat lagi"
        });
        
        this.removeSpinner(submitButton)
      // }, 2000);
    },
    onLogout() {
      if (!(['send', 'notify']).includes(this.status)) {
        return
      }
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "auth-login" }))
        .catch(()=>{})
    },
    onProcessLogout(time) {

      this.timeLogoutProcess = setInterval(() => {
        const now = new Date().getTime();
        const distance = time - now;
        this.timeLogout = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
          clearInterval(this.timeLogoutProcess);
          this.$store
            .dispatch(DELACCOUNT)
            .then(() => this.$router.push({ name: "auth-login" }))
            .catch(()=>{})
        }
      }, 1000);
    },
    onSubmit() {
      if (this.status != 'form') {
        return
      }
      this.$refs.form.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (!this.$route.query.token) {
          ErrorService.message({
              action: 'Peringatan',
              message: 'Token Invalid',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_change_password_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          ProfileService.changePasswordVerify({ ...this.form, token: this.$route.query.token })
            .then((res) => {              
                this.removeSpinner(submitButton)      
                if (!res.data.status) {
                  return ErrorService.message(res.data)
                }

                this.show = false
                this.status = 'change'
                this.$nextTick(() => {
                  this.show = true
                  this.onProcessLogout((new Date().getTime()) + 10000)
                })

            })
            .catch((err) => {
              this.removeSpinner(submitButton)
              ErrorService.status(err)
            })
        }, 2000);
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    activeUserInfo() {
      return this.$store.getters.currentUser
    }
  },
  mounted() {
    this.getPasswordStatus()
  },
};
</script>
